import React, { useState } from "react";
import styled from "styled-components";
import { ConfirmationPopup } from "./components/ConfirmationPopup";
import { syncData } from "Actions/devtools/devtoolSyncData";

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
  padding-top: 30px;
  
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Card = styled.div`
  background: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 250px;
  font-size: 15px;
  cursor: pointer;
`;

export const DevToolsList = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSyncData = async () => {
      setLoading(true);
      try {
        await syncData();
        setIsOpen(false); // close only if sync succeeds
      } catch (err: unknown) {
        // eslint-disable-next-line no-console
        console.error("Sync failed:", err);
      } finally {
        setLoading(false);
      }
    };

    const tools = [
        "Sync data from production to staging",
        "Sync data from production to staging",
        "Sync data from production to staging",
        "Sync data from production to staging",
        "Sync data from production to staging",
    ];
    return (
        <FlexContainer>
            {tools.map((tool, index) => (
            <Card data-test="devTool" key={index} onClick={() => setIsOpen(true)}>
                {tool} <span>→</span>
            </Card>
            ))}
            {isOpen && (
              <ConfirmationPopup
                title="Sync data"
                loading={loading}
                content="Are you sure you want to continue?"
                handleClose={() => setIsOpen(false)}
                handleConfirm={() => handleSyncData()}
              />
            )}
        </FlexContainer>
    );
};

import React from "react";
import styled from "styled-components";

import { BUTTON_SECONDARY, BUTTON_PRIMARY } from "@madecomfy/webooi";

import Generic from "Components/Popup/GenericWrapper";
import Popup from "Components/Popup";
import { Body } from "Components/Typography";

interface IProps {
  title: string;
  loading?: boolean;
  content?: string;
  handleClose: (...agrs: any) => any;
  handleConfirm: (...agrs: any) => any;
}

const Content = styled(Body)`
  margin-bottom: 32px;
`;

export const ConfirmationPopup: React.FC<IProps> = (props) => {
  const {
    title,
    loading = false,
    content = "",
    handleClose,
    handleConfirm,
  } = props;
  return (
    <Popup
      body={
        <Generic
          body={<Content>{content}</Content>}
          buttons={[
            { label: "Cancel", onClick: handleClose, type: BUTTON_SECONDARY, testId: "cancel" },
            {
              label: "Confirm",
              onClick: () => {
                handleConfirm();
              },
              type: BUTTON_PRIMARY,
              testId: "confirm",
            },
          ]}
          title={title}
          loading={loading}
        />
      }
      handleClose={handleClose}
      width={450}
    />
  );
};

import React from "react";
import styled from "styled-components";
import { H2 } from "Components/Typography";
import { DevToolsList } from "./DevToolsList";

const Wrapper = styled.div`
  margin: 24px;
  padding: 24px;
`;

export const DevTools = () => {
    return (
        <Wrapper>
            <H2 color="#4A4F54">Dev tools</H2>
            <DevToolsList />
        </Wrapper>
    );
};

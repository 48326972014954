import { ROLE } from "Constants/userRoles";
import { isAuthorized } from "Utils/methods/authentication";

const {
  ADMIN,
  CHANNEL_MANAGER,
  CONTENT_CREATOR,
  DEVELOPER,
  FINANCE_MANAGER,
  LICENSEE,
  OWNER,
  STAFF,
} = ROLE;

const restrictions = [
  { path: /^\/channels\/*/, requiredRole: [CHANNEL_MANAGER] },
  { path: /^\/design-system/, requiredRole: [DEVELOPER] },
  { path: /^\/devtools/, requiredRole: [DEVELOPER] },
  { path: /^\/reservation-all\/*/, requiredRole: [ADMIN, STAFF, LICENSEE] },
  { path: /^\/statements/, requiredRole: [ADMIN, STAFF, LICENSEE] },
  { path: /^\/properties/, requiredRole: [ADMIN, STAFF, OWNER, LICENSEE, CONTENT_CREATOR] },
  { path: /^\/releases\/*/, requiredRole: [DEVELOPER] },
  { path: /^\/reservations\/*/, requiredRole: [ADMIN, STAFF, LICENSEE] },
  { path: /^\/resources\/*/, requiredRole: [ADMIN, STAFF, LICENSEE] },
  { path: /^\/users\/*/, requiredRole: [ADMIN, STAFF] },
  { path: /^\/knowledgecentre\/*/, requiredRole: [ADMIN, STAFF, LICENSEE] },
  { path: /^\/balances\/*/, requiredRole: [FINANCE_MANAGER] },
];

const canUserAccessPath = (user: { [key: string]: any }, pathname: string) => {
  if (pathname === "/" || pathname === "/index.html") {
    // there is nothing at root, always redirect.
    return false;
  }

  const matches = restrictions.filter((rule) => {
    return rule.path.test(pathname);
  });
  if (matches.length === 1) {
    // found a match, check if allowed.
    return Boolean(
      matches.find((rule) => {
        const { requiredRole } = rule;
        const ok = isAuthorized(requiredRole, user.roles);
        // console.log("canUserAccessPath", ok, rule, requiredRole, user);
        return ok;
      }),
    );
  }
  if (matches.length > 1) {
    // eslint-disable-next-line
    console.warn(
      "canUserAccessPath error - more than one match!",
      user,
      pathname,
    );
    return false;
  }
  // if there are no matches, it is not a sensitive path
  return true;
};

export default canUserAccessPath;

import React, { Suspense } from "react";

const BookingLazy = React.lazy(
  () => import("Containers/Booking" /* webpackChunkName: "reservations" */),
);
const DesignSystemLazy = React.lazy(
  () =>
    import(
      "Containers/DesignSystem/DesignSystem" /* webpackChunkName: "design-system" */
    ),
);
const ReservationsLazy = React.lazy(
  () =>
    import("Containers/Reservations" /* webpackChunkName: "reservation-all" */),
);
const PropertyViewLazy = React.lazy(
  () =>
    import("Containers/PropertyView" /* webpackChunkName: "property-view" */),
);

const GenericLoader = (componentName: any) => <div>Loading {componentName}...</div>;
const GenericLazy = (Component: any, componentName: string) => (props: {[key: string]: any}) => {
  return (
    <Suspense fallback={GenericLoader(componentName)}>
      <Component {...props} />
    </Suspense>
  );
};

export const Booking = GenericLazy(BookingLazy, "reservations");
export const DesignSystem = GenericLazy(DesignSystemLazy, "design system");
export const Reservations = GenericLazy(ReservationsLazy, "reservation-all");
export const PropertyView = GenericLazy(PropertyViewLazy, "property view");

import dayjs from "dayjs";

import { formatUserName } from "Utils";
import { PAYMENT_NOTE, RESERVATION_NOTE } from "Constants/bookingNoteTypes";
import { transformQuote } from "./quoteRestore";

const stripBasicHTML = (str: string) => {
  if (typeof str !== "string") {
    // probably null
    return str;
  }
  return str
    .replace(/&nbsp;/g, " ") // spaces
    .replace(/↵/g, "\n") // carriage returns
    .replace(/<\/div>/g, "") // ignore closing divs
    .replace(/<(div|br)\/?>/g, "\n") // carriage returns for all open div and brs
    .replace(/\n\n+/g, "\n\n"); // anything more than a double carriage return is replaced with a double carriage return
};

const getStatusFlag = (dueOn: string | number | Date, paymentType: string, status: string) => {
  const isFutureDate = dayjs().isBefore(dueOn) || dayjs().isSame(dueOn, "D");
  if (
    status === "unpaid" &&
    (paymentType === "accommodation-charge" ||
      paymentType === "deposit-charge" ||
      paymentType === "one-off-charge") &&
    isFutureDate
  ) {
    return "scheduled";
  }
  return null;
};

const getProperty = ({ included, relationships }: {[key: string]: any}) => {
  if (!relationships.property) {
    return undefined;
  }
  const property = included.find((item: {[key: string]: any}) => {
    return (
      item.id === relationships.property.data?.id &&
      item.type === "property"
    );
  });
  if (!property) {
    return undefined;
  }
  const address = included.find((item: {[key: string]: any}) => {
    return (
      item.id === relationships.property.data?.id &&
      item.type === "property-address"
    );
  });
  return { ...property?.attributes, id: relationships.property.data?.id, address: {...address?.attributes} };
};

const getCards = ({ included, relationships }: {[key: string]: any}) => {
  const cards = included
    .filter((item: {[key: string]: any}) => {
      return (
        relationships.cards &&
        relationships.cards.data.find(({ id }: {[key: string]: any}) => id === item.id)
      );
    })
    .map((card: {[key: string]: any}) => {
      const { id, attributes } = card;
      const { expiryMonth, expiryYear, name, ...rest } = attributes;
      // establish an expiry right away so we have a string throughout the app
      // otherwise {expiryMonth: null, expiryYear: null} may be passed around.
      const expiry =
        expiryMonth && expiryYear
          ? `${expiryMonth}/${expiryYear.slice(-2)}`
          : "--/--";
      return { id, expiry, name, ...rest };
    });
  return cards;
};

const getCleanCancel = ({ included, relationships }: {[key: string]: any}) => {
  if (!relationships["clean-cancellation"]) {
    return {};
  }
  const cleanCancel = included.find((item: {[key: string]: any}) => {
    return (
      item.id === relationships["clean-cancellation"].data?.id &&
      item.type === "clean-cancellation"
    );
  });
  return { ...cleanCancel?.attributes };
};

const getPayments = ({ included, relationships }: {[key: string]: any}) => {
  const payments = included
    .filter((item: {[key: string]: any}) => {
      return (
        relationships.payments &&
        relationships.payments.data.find(({ id }: {[key: string]: any}) => id === item.id)
      );
    })
    .map(
      ({
        id,
        attributes: {
          amount,
          dueOn,
          errorDescription,
          isRefund,
          isThreeDSecure,
          method,
          note,
          paidAt,
          paymentType,
          receiptUrl,
          refundableAmount,
          status,
        },
        relationships: {
          card: {
            data,
          },
          charge: {
            data: chargeData,
          },
        },
      }: {[key: string]: any}) => {
        return {
          id,
          amount,
          cardId: data ? data.id : null,
          chargeId: chargeData ? chargeData.id : null,
          dueOn,
          errorDescription,
          isRefund,
          isThreeDSecure,
          method,
          note: stripBasicHTML(note), // note this is a copy of the item in bookings.notes
          paidAt,
          paymentType,
          receiptUrl,
          refundableAmount,
          status,
          statusFlag: getStatusFlag(dueOn, paymentType, status),
        };
      },
    );
  return payments;
};

const getQuotes = ({ included, relationships }: {[key: string]: any}) => {
  if (!relationships.quotes) {
    return;
  }
  const quotes = relationships.quotes.data.flatMap(({ id }: {[key: string]: any}) => {
    const quote = included.find((item: {[key: string]: any}) => {
      return item.type === "quote" && id === item.id;
    });
    return transformQuote({ data: quote });
  });
  return quotes;
};

const getCurrentQuote = ({ included, relationships }: {[key: string]: any}) => {
  if (!relationships["current-quote"]) {
    return;
  }
  const quoteId = relationships["current-quote"].data?.id;
  const quote = quoteId && included.find((item: {[key: string]: any}) => {
    return item.type === "quote" && item.id === quoteId;
  });
  return quote ? transformQuote({ data: quote }) : [];
};

const getNotes = ({ included, relationships }: {[key: string]: any}) => {
  if (!relationships.notes) {
    return [];
  }
  const notes = included
    .filter((item: {[key: string]: any}) => {
    //  const {
      //  attributes: { content },
    //  } = item;
    //  if (!content) return false; // remove empty notes.
      return relationships.notes.data.find(({ id }: {[key: string]: any}) => {
        return id === item.id;
      });
    })
    .map((item: {[key: string]: any}) => {
      if (!item.relationships) {
        // error with this item - should have: item.relationships.payment.data
        return item.attributes;
      }
      const {
        attributes: { content: contentRaw, createdAt, from, tag },
        relationships: {
          payment: { data },
        },
      } = item;

      const status = data ? PAYMENT_NOTE : RESERVATION_NOTE;
      const content = stripBasicHTML(contentRaw);
      return { content, createdAt, from, status, tag };
    })
    .sort((a: {[key: string]: any}, b: {[key: string]: any}) => {
      return a.createdAt > b.createdAt ? -1 : 1;
    });
  return notes;
};

const getMessages = ({ included, relationships }: {[key: string]: any}) => {
  if (!relationships.messages) {
    return [];
  }
  const messages = relationships.messages.data.flatMap(({ id }: {[key: string]: any}) => {
    const message = included.find((item: {[key: string]: any}) => {
      return item.type === "booking-message" && id === item.id;
    });
    return { id, ...message.attributes };
  });
  return messages;
};
const getMerchant = ({ included, relationships }: {[key: string]: any}) => {
  if (!relationships.merchant) {
    return {};
  }
  const merchant = included.find((item: {[key: string]: any}) => {
    return (
      item.id === relationships.merchant.data?.id &&
      item.type === "merchant-account"
    );
  });
  const platform = merchant?.relationships?.platform?.data && included.find((item: {[key: string]: any}) => {
    return (
      item.id === merchant?.relationships?.platform?.data?.id &&
      item.type === "merchant-account"
    );
  });
  return { ...merchant?.attributes, platform: {...platform?.attributes} };
};
const transformBooking = (booking: any, withQuotes: boolean = true) => {
  // extract all the fields we need
  const {
    data: {
      attributes: {
        bookedAt,
        booker,
        calendarLink,
        cancelledAt,
        cleaningRequired,
        corporatePartner,
        createdBy,
        currency,
        depositSummary,
        finance,
        firstGuestPortalAccessedAt,
        isCleaningOutsourced,
        lastGuestPortalAccessedAt,
        longCode,
        payment,
        paymentSummary,
        payout,
        payoutBreakdown,
        payoutSummary,
        status,
        timing,
        verification,
      },
      relationships,
    },
    included,
  } = booking;
  const findReview = included.find((item: {[key: string]: any}) => {
    return item.type === "review" && item.id === relationships.review.data.id;
  });
  const review = findReview && findReview.attributes;

  // since this value is a string, fix it immediately to never display .00
  payout.managementFeePercentage = payout.managementFeePercentage.replace(
    ".00",
    "",
  );

  const cards = getCards({ included, relationships });
  const cleanCancel = getCleanCancel({ included, relationships });
  const merchant = getMerchant({ included, relationships });
  const messages = getMessages({ included, relationships });
  const payments = getPayments({ included, relationships });
  const currentQuote = getCurrentQuote({ included, relationships });
  const minimal = transformBookingMinimal(booking);

  const clean = {
    ...minimal,
    bookedAt,
    booker,
    cancelledAt,
    cards,
    calendarLink,
    cleanCancel,
    cleaningRequired,
    corporatePartner,
    createdBy,
    currency,
    currentQuote,
    depositSummary,
    finance,
    firstGuestPortalAccessedAt,
    isCleaningOutsourced,
    lastGuestPortalAccessedAt,
    longCode,
    merchant,
    messages,
    payment,
    paymentSummary,
    payments,
    payout,
    payoutBreakdown,
    payoutSummary,
    review,
    status,
    timing,
    verification,
  };

  if (withQuotes) {
    const quotes = getQuotes({ included, relationships });
    return { ...clean, quotes };
  }
  return clean;
};

// we only need `id`, `guest`, `status`, `startDate`, `endDate` & `externalSource` for the calendar functionality,
// and the request block modal uses (request block only needs `startDate` & `endDate`)
const transformBookingMinimal = ({ data, included }: {[key: string]: any}) => {
  const bmap = (booking: {[key: string]: any}) => {
    const {
      id,
      attributes: {
        booker,
        createdBy,
        currency,
        depositSummary,
        finance,
        guest,
        payoutBreakdown,
        paymentSummary,
        payoutSummary,
        status,
        timing,
        timing: { checkIn, checkOut, hasEarlyEta, hasLateEtd },
        cancellationPolicy,
      },
      relationships,
    } = booking;
    const property = getProperty({ included, relationships });
    const notes = getNotes({ included, relationships });
    return {
      // always keep startDate, endDate, status & id at top of this object in this order. this is to ease debugging via console etc.
      startDate: checkIn?.substring(0, 10), // calendar only needs day data to render
      endDate: checkOut?.substring(0, 10), // ... whilst we are only in australia anyway!
      status,
      id,
      // all other attributes can be in alphabetical order
      externalSource: included.find((item: {[key: string]: any}) => {
        return (
          item.id === relationships.resources.data.id &&
          item.type === "external-resources"
        );
      }).attributes,
      createdBy,
      currency,
      depositSummary,
      finance,
      guest: { ...guest, name: formatUserName(guest) },
      hasEarlyEta,
      hasLateEtd,
      notes,
      payoutBreakdown,
      paymentSummary,
      payoutSummary,
      property,
      timing,
      cancellationPolicy,
      booker,
    };
  };
  if (Array.isArray(data)) {
    return data.map((i) => {
      return bmap(i);
    });
  }
  return bmap(data);
};

export { transformBooking, transformBookingMinimal };

import canUserAccessPath from "HOC/withSessionCheck/canUserAccessPath";
import { isAuthorized } from "Utils/methods/authentication";
import { ROLE } from "Constants/userRoles";

const getMenuItems = ({ roles }:{roles: any[]}) => {
  // handle special case for properties.
  let settings = {};
  if (isAuthorized(roles, [ROLE.STAFF, ROLE.OWNER, ROLE.LICENSEE])) {
    settings = { label: "Settings", link: "/settings", mobileOnly: true };
  }
  return [
  //  propertiesItem, // this will be filtered out by `link` check below if it is empty object.
    { label: "Properties", link: "/properties" },
    { label: "Reservations", link: "/reservation-all" },
    { label: "Users", link: "/users" },
    { label: "Channels", link: "/channels" },
    { label: "Statements", link: "/statements" },
    { label: "Balances", link: "/balances" },
    { label: "Dev Tools", link: "/devtools" },
    { label: "Resources", link: "/resources" },
    {
      label: "FAQ",
      link: "https://helpcentre.madecomfy.com.au/s/topic/0TO5g000000TmxZGAS/madecomfy-dashboard",
    },
    { label: "Knowledge Centre", link: "/knowledgecentre" },
    { label: "Releases", link: "/releases" },
    settings,
    { label: "Logout", link: "/logout", mobileOnly: true },
  ].filter(({ link } : any) => link && canUserAccessPath({ roles }, link));
};

export default getMenuItems;

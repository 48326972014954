import { API_URL } from "Config";
import { NetworkError, NO_CONTENT } from "Utils/Network";

export const syncData = async () => {
    const response = await fetch(`${API_URL}v3/dev-tools/sync-staging-data`, {
        mode: "cors",
        credentials: "include",
        method: "POST",
    });

    if (response.status !== NO_CONTENT) {
        throw new NetworkError(response);
    }
    return true;
};

import dayjs, { ConfigType } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const dTZ = "Australia/Sydney";
// TODO: change ymd to yyyymmdd
export function ymd(date: ConfigType) {
  const m = dayjs.isDayjs(date) ? date : dayjs(date);
  return m.format("YYYY-MM-DD");
}
export function yyyymmdd(date: ConfigType, TZ?: string) {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("YYYY-MM-DD");
}
export const dmy = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("DD/MM/YYYY");
};
export const formatDate = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("Do MMM YY");
};
export const formatDateWithFullYear = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("DD MMM YYYY");
};
export const formatDateFull = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("Do MMMM YYYY");
};
export const formatDateWithDay = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("ddd, Do MMM YY");
};
export const formatDateWithoutYear = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("ddd D MMM");
};
export const formatDateWithTime = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("Do MMM YY, h:mm a");
};
export const formatFullDateWithTime = (date: ConfigType, TZ?: string) => {
  return dayjs(date)
    .tz(TZ || dTZ)
    .format("Do MMMM YYYY, hA");
};
export const formatDateWithDynamic = (date :string, format :string, TZ? :any) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format(format);
};
export const formatTime = (date :string, TZ? :any) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("h:mm a");
};
export const formatTimeWithoutMin = (date :string, TZ? :any) => {
  if (dayjs(date).minute() === 0) {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("hA");
  } else {
    return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("h:mm a");
  }
};
export const formatHour = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("HH:mm:ss");
};
export const formatISODateTime = (date: string, time: string) => date.concat("T" + time);

export const formatMonthYear = (date: ConfigType, TZ?: string) => {
  return dayjs
    .utc(date)
    .tz(TZ || dTZ)
    .format("MMM YYYY");
};

import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";
import { Tooltip } from "@madecomfy/webooi";

import expand from "./assets/exp-col.svg";
import Icon from "Components/Icon";

export const MenuItem = css`
  border-radius: 4px;
  color: ${({ theme }) => theme.charcoal};
  cursor: pointer;
  display: block;
  font-family: InterMedium;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 0 18px 0 18px;
  position: relative;
  white-space: nowrap;
  background: ${({ theme }) => theme.white};
  :hover {
    background: ${({ theme }) => theme.background.subtle3};
  }
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    display: none;
  }
`;
const MenuItemInternal = styled(Link)<any>`
  ${MenuItem}
  ${({ selected }) => {
    if (selected) {
      return css`
        svg {
          fill: ${({ theme }) => theme.icon.default};
        }
        span {
          color: ${({ theme }) => theme.text.default};
        }
      `;
    }
  }}
`;
const Text = styled.span<any>`
  color: ${({ theme }) => theme.text.subtle};
  margin-left: 16px;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  vertical-align: text-top;
  display: none;
  animation: fadeIn 1.5s;
  -webkit-animation: fadeIn 1.5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${({ isMenuVisible }) =>
    isMenuVisible &&
    css`
      display: inline-block;
    `};
`;
const Selected = styled.span<any>`
  ${({ selected }) => {
    if (selected) {
      return css`
        background: ${({ theme }) => theme.background.brand};
        border-radius: 0 4px 4px 0;
        width: 4px;
        height: 32px;
        position: absolute;
        left: -16px;
        margin: 8px 0;
      `;
    }
  }}
`;
const MenuItemExternal = styled.a`
  ${MenuItem}
`;
export const LineSpacer = styled.p`
  border-top: 2px solid ${({ theme }) => theme.border.default};
  margin-top: 16px;
  margin-bottom: 16px;
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    display: none;
  }
`;
const Menu = styled.div`
  svg {
    cursor: pointer;
    vertical-align: middle;
    margin: 12px 0;
  }
  div {
    p {
      display: none;
    }
  }
  div:first-of-type {
    p {
      display: block;
      @media (max-width: ${({ theme }) => theme.maxMobile}) {
        display: none;
      }
    }
  }
`;
const Expand = styled.div<any>`
  margin: 8px 16px 24px 16px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  gap: 16px;
  ${({ isMenuVisible }) =>
    isMenuVisible &&
    css`
      left: 0;
      right: 0;
      img {
        transform: rotate(180deg);
      }
    `};
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    display: none;
  }
`;
const Close = styled.p`
  margin: 0;
  font-family: InterMedium;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.charcoal};
`;
const WrapperTooltip = styled.div`
  position: absolute;
  top: 0px;
  left: 8px;
  width: 48px;
  height: 48px;
  div[data-test="tooltip"] {
    width: auto;
    left: 66px;
    bottom: 4px;
    svg {
      display: none;
    }
  }
`;

interface IProps extends RouteComponentProps {
  items: any;
  isMenuOpen: any;
  menuOpen: boolean;
}
export const getIconURL = new Map([
  ["properties", "house"],
  ["reservations", "suitcase"],
  ["statements", "statement"],
  ["users", "users"],
  ["channels", "channel"],
  ["dev tools", "devtool"],
  ["guide", "book"],
  ["resources", "resources"],
  ["faq", "faq"],
  ["releases", "rocket"],
  ["knowledge centre", "knowledge"],
  ["balances", "bank"],
]);

const DesktopMenu: React.FC<IProps> = ({
  location: { pathname },
  items,
  isMenuOpen,
  menuOpen,
}) => {
  const toggleMenu = () => {
    isMenuOpen(!menuOpen);
  };
  const [showTooltip, setShowTooltip] = useState(false);
  let timeoutId: any;
  const handleMouseEnter = () => {
    // Set a delay of, for example, 500 milliseconds before showing the tooltip
    timeoutId = setTimeout(() => {
      setShowTooltip(true);
    }, 300);
  };

  const handleMouseLeave = () => {
    // Clear the timeout if the user leaves before the delay expires
    clearTimeout(timeoutId);
    setShowTooltip(false);
  };
  return (
    <>
      <Menu>
        {items
          .filter((item: { [key: string]: any }) => !item.mobileOnly)
          .map(({ label, link }: any) => {
            const testId = `test-id-menu-item-${label
              .toLowerCase()
              .replace(/\s/, "-")}`;

            // currently there is only one item in the nav which starts with http, and that happens to be the help menu.
            // this won't scale obviously, every link that starts with http will have "Help" prepended, but we are under the pump!
            if (
              link.startsWith("http") ||
              ["Knowledge Centre", "Resources"].includes(label)
            ) {
              return (
                <div key={link}>
                  <LineSpacer />
                  {label === "Knowledge Centre" || label === "Resources" ? (
                    <MenuItemInternal
                      id={testId}
                      key={link}
                      to={link}
                      selected={pathname !== "/" && pathname.startsWith(link)}
                    >
                      <span></span>
                      <Icon
                        type={getIconURL.get(label.toLowerCase()) as any}
                        size={24}
                        fill="#939296"
                      />
                      {!menuOpen && (
                        <WrapperTooltip
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          {showTooltip && (
                            <Tooltip
                              hideIcon={true}
                              position="left"
                              text={label}
                              testId="tooltip"
                              timeOut={100}
                            />
                          )}
                        </WrapperTooltip>
                      )}
                      <Text isMenuVisible={menuOpen}>{label}</Text>
                    </MenuItemInternal>
                  ) : (
                    <MenuItemExternal
                      href={link}
                      id={testId}
                      key={link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span></span>
                      <Icon
                        type={getIconURL.get(label.toLowerCase()) as any}
                        size={24}
                        fill="#939296"
                      />
                      {!menuOpen && (
                        <WrapperTooltip
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          {showTooltip && (
                            <Tooltip
                              hideIcon={true}
                              position="left"
                              text={label}
                              testId="tooltip"
                              timeOut={100}
                            />
                          )}
                        </WrapperTooltip>
                      )}
                      <Text isMenuVisible={menuOpen}>{label}</Text>
                    </MenuItemExternal>
                  )}
                </div>
              );
            }
            return (
              <MenuItemInternal
                id={testId}
                key={link}
                to={link}
                selected={pathname !== "/" && pathname.startsWith(link)}
              >
                <Selected
                  selected={pathname !== "/" && pathname.startsWith(link)}
                />
                <Icon
                  type={getIconURL.get(label.toLowerCase()) as any}
                  size={24}
                  fill="#939296"
                />
                {!menuOpen && (
                  <WrapperTooltip
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {showTooltip && (
                      <Tooltip
                        hideIcon={true}
                        position="left"
                        text={label}
                        testId="tooltip"
                        timeOut={100}
                      />
                    )}
                  </WrapperTooltip>
                )}
                <Text isMenuVisible={menuOpen}>{label}</Text>
              </MenuItemInternal>
            );
          })}
      </Menu>
      <Expand isMenuVisible={menuOpen} onClick={toggleMenu}>
        <img alt="expand" src={expand} />
        {menuOpen && <Close>Close</Close>}
      </Expand>
    </>
  );
};

export default withRouter(DesktopMenu as any);

export const titleCaseWord = (str: string) => {
  if (typeof str !== "string") return "";
  return str
    .split("")
    .map((c, i) => (i ? c.toLowerCase() : c.toUpperCase()))
    .join("");
};

// converts a string like `hello_world` or `hello world` to `Hello World`
export const titleCase = (str: string) => {
  if (typeof str !== "string") return "";
  // matches a space or an underscore
  return str
    .split(/[\s_-]/)
    .map(titleCaseWord)
    .join(" ");
};

export const titleCaseWithHyphen = (str: string) => {
  if (typeof str !== "string") return "";
  // matches a space
  return str.split(/[\s_]/).map(titleCaseWord).join(" ");
};

// snakeCaseToTitleCase is deprecated
export const snakeCaseToTitleCase = titleCase;

export const pluralise = (number: number, single: string, plural: string) => {
  if (typeof number !== "number") return "";
  return `${number} ${number === 1 ? single : plural}`;
};

export const camelCase = (str: string) =>
  str
    .split(" ")
    .map((s, index) => (index === 0 ? s.toLowerCase() : titleCaseWord(s)))
    .join("");

export const formatUserName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  if (firstName === "Owner" && lastName === "Owner") {
    return "Owner";
  }
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return "No name";
};

export const convertToURL = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + "</a>";
  });
};

export const toSentenceCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const extractPhotoUrl = (url: string): string => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};
